<template>
    <div class="box">
        <!-- {{ codes }}
        {{ userInfo }}
        <div @click="getUserInfo">
            登录微信
        </div> -->
        <loading :show="showLoading" :msg="'登录中...'"></loading>
    </div>
</template>
<script>
import { passenger } from "@/api";
import { Toast } from "vant";
import Loading from "./comm/loading-login.vue";
// import CONFIG from "../../config";
export default {
    components: {
        Loading: Loading
    },
    data() {
        return {
            resouce: '',
            codes: "",
            userInfo: "",
            showLoading: false
        };
    },
    mounted() {
        this.showLoading = true
        if(this.$route.query.resouce == 'info') {
            let urls = encodeURIComponent(`http://shweb.qiyasoft.top/login?id=${this.$route.query.id}&resouce=tinfo`)
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=${urls}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        } else {
            this.getUserInfo()
        }
    },
    // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa77ba17b66c5cad5&redirect_uri=http://sh.yayale.top/login&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
    methods: {
        getQueryString: function (name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(decodeURI(r[2]));
            }
        },
        getUserInfo() {
            let params = {
                code: this.getQueryString("code"),
            };
            passenger.getCodeToUser(params)
                .then((res) => {
                    if(res.data.code == 200) {
                        localStorage.setItem("token", res.data.data.access_token);
                        localStorage.setItem("user", JSON.stringify(res.data.data.user));
                        if(this.$route.query.resouce == 'my') {
                            setTimeout(() => {
                                window.location.href = '/wdfb'
                                // this.$router.replace({
                                //         name: 'wdfb',
                                //         query: {}
                                //     })
                                this.showLoading = false
                            }, 500)
                        } else if(this.$route.query.resouce == 'tinfo') {
                            this.$router.replace({
                                        name: 'trip-info',
                                        query: {
                                            id: this.$route.query.id,
                                        }
                                    })
                        }  else if (this.$route.query.resouce == 'sh') {
                            this.$router.replace({
                                        name: 'czsh',
                                        query: {}
                                    })
                        }  else if (this.$route.query.resouce == 'shq') {
                            this.$router.replace({
                                        name: 'posts-list',
                                        query: {}
                                    })
                        } else if (this.$route.query.resouce == 'tsjy') {
                            this.$router.replace({
                                        name: 'my-question-list',
                                        query: {}
                                    })
                        } else if (this.$route.query.resouce == 'cltsjy') {
                            this.$router.replace({
                                        name: 'all-question-list',
                                        query: {}
                                    })
                        } else if (this.$route.query.resouce == 'chat') {
                            this.$router.replace({
                                        name: 'chat',
                                        query: {
                                            toId: this.$route.query.toId
                                        }
                                    })
                        } else if (this.$route.query.resouce == 'goods-chat') {
                            this.$router.replace({
                                        name: 'goods-chat',
                                        query: {
                                            toId: this.$route.query.toId
                                        }
                                    })
                        } else if (this.$route.query.resouce == 'goodslist') {
                            this.$router.replace({
                                        name: 'goods-list',
                                        query: {}
                                    })
                        } else {
                            if(res.data.data.user.types==null) {
                                setTimeout(() => {
                                    window.location.href = '/auth-page'
                                    // this.$router.replace({
                                    //         name: 'auth-page',
                                    //         query: {}
                                    //     })
                                    this.showLoading = false
                                }, 500)
                            } else {
                                if(res.data.data.user.agree=='2') {
                                    window.location.href = '/auth-page'
                                } else {
                                    window.location.href = '/zybrlb'
                                }
                                // this.$router.replace({
                                //     name: 'zybrlb'
                                // })
                            }
                        }
                        
                        
                    } else {
                        Toast.fail('登录失败')
                        setTimeout(() => {
                        //         window.location.href =
                        // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4d12960aef5beb75&redirect_uri=http://yjck.yayale.top/login&response_type=code&scope=snsapi_userinfo&state=STATE`
                        }, 1500)
                    }
                    // this.userInfo = JSON.stringify(res)
                    
                    // console.log(res)
                })
                .catch(() => {
                    Toast.fail('登录失败')
                        setTimeout(() => {
                        //         window.location.href =
                        // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4d12960aef5beb75&redirect_uri=http://39xmby.natappfree.cc/login&response_type=code&scope=snsapi_userinfo&state=STATE`
                        }, 1500)
                });
        },
    },
};
</script>
